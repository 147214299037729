import React, { useEffect, useState } from 'react'
import styles from './Authorization.module.scss'
import robot1 from 'assets/images/robot1.png'
import robot2 from 'assets/images/robot2.png'
import robot3 from 'assets/images/robot3.png'
import robot4 from 'assets/images/robot4.png'
import logo from 'assets/images/Logo.png'
import api from '../../api/api'
import classNames from 'classnames'

const robotsBG = [
  { src: robot1, style: styles.robot1 },
  { src: robot2, style: styles.robot2 },
  { src: robot3, style: styles.robot3 },
  { src: robot4, style: styles.robot4 },
]

const robotInd = Math.round(Math.random() * 3)

type Props = {
  onChange: (user: User) => void
}

const Authorization = (props: Props) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [savedUser, setSavedUser] = useState<User>()
  const [error, setError] = useState(false)

  const bg = robotsBG[robotInd]
  const isFieldsEmpty = username.length === 0 || password.length === 0

  const onSubmit = async () => {
    if (!isFieldsEmpty) {
      const res = await api.postLogin(username, password)
      if (res !== null) {
        localStorage.setItem('token', res.token)
        props.onChange(res)
      } else setError(true)
    }
  }

  const onUseSavedUser = () => {
    if (savedUser !== undefined) props.onChange(savedUser)
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token !== null) {
      api.getUser(token).then((r) => {
        // if (r !== null) props.onChange({ ...r, token })
        if (r !== null) setSavedUser({ ...r, token })
      })
    }
  }, [])

  if (savedUser !== undefined) {
    return (
      <div className={styles.page}>
        <img src={bg.src} className={styles.robotBG + ' ' + bg.style} />
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onUseSavedUser()
          }}
          className={styles.form}
          style={error ? { border: '2px solid red' } : {}}>
          <div className={styles.logo}>
            <img src={logo} />
            <span>/ AIWA</span>
          </div>
          <h2>Sign in</h2>
          <div className={styles.fields}>
            <div className={styles.field}>
              <label>Username</label>
              <input value={savedUser.username} readOnly />
            </div>
          </div>
          <button type={'submit'} className={styles.submitBtn}>
            Sign in
          </button>
          <button
            onClick={() => setSavedUser(undefined)}
            type={'button'}
            className={classNames([styles.submitBtn, styles.otherUserBtn])}>
            Other user
          </button>
        </form>
      </div>
    )
  } else {
    return (
      <div className={styles.page}>
        <img src={bg.src} className={styles.robotBG + ' ' + bg.style} />
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
          onChange={() => setError(false)}
          className={styles.form}
          style={error ? { border: '2px solid red' } : {}}>
          <div className={styles.logo}>
            <img src={logo} />
            <span>/ AIWA</span>
          </div>
          <h2>Sign in</h2>
          <div className={styles.fields}>
            <div className={styles.field}>
              <label>Username</label>
              <input value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className={styles.field}>
              <label>Password</label>
              <input value={password} onChange={(e) => setPassword(e.target.value)} type={'password'} />
            </div>
          </div>
          <button type={'submit'} disabled={isFieldsEmpty} className={styles.submitBtn}>
            Sign in
          </button>
        </form>
      </div>
    )
  }
}

export default Authorization
