import React, { useEffect, useState } from 'react'
import styles from './Chat.module.scss'
import volumeImg from 'assets/images/Volume.svg'
import volumeOffImg from 'assets/images/VolumeOff.svg'
import sendImg from 'assets/images/Send.svg'
import microImg from 'assets/images/Mic.svg'
import Speech from 'classes/Speech'
import classNames from 'classnames'
import { WS } from 'classes/WS'
import AITeacher from 'classes/AITeacher'

const MicrophoneBtn = () => {
  const [isMicOn, setIsMicOn] = useState(Speech.isMicOn)
  const [isListenPause, setIsListenPause] = useState(Speech.isPause)

  const onToggle = () => {
    if (isMicOn) {
      console.log('Click on Micro - Disable')
      Speech.stopListen()
    } else {
      console.log('Click on Micro - Enable')
      Speech.listen()
    }
  }

  useEffect(() => {
    const unsubSpeechListen = Speech.subscribe({ type: 'mic', cb: setIsMicOn })
    const unsubSpeechPause = Speech.subscribe({ type: 'pause', cb: setIsListenPause })

    return () => {
      // UEVoiceSyncUnsubscribe()
      unsubSpeechListen()
      unsubSpeechPause()
      Speech.stopListen()
    }
  }, [])

  return (
    <button
      onClick={onToggle}
      className={classNames([styles.micBtn, { [styles.micBtnDisabled]: isListenPause && !isMicOn }])}>
      <img src={microImg} />
      {isMicOn && <span className={styles.redDot} />}
    </button>
  )
}

const VoiceBtn = () => {
  const [isVoiceOn, setIsVoiceOn] = useState(AITeacher.isVoiceEnable)

  const onToggle = () => {
    if (AITeacher.isVoiceEnable) {
      console.log('Click on Voice - Disable')
      AITeacher.isVoiceEnable = false
      AITeacher.stopSpeak()
      setIsVoiceOn(false)
    } else {
      console.log('Click on Voice - Enable')
      AITeacher.isVoiceEnable = true
      setIsVoiceOn(true)
    }
  }

  return (
    <button onClick={onToggle}>
      <img src={isVoiceOn ? volumeImg : volumeOffImg} style={isVoiceOn ? {} : { width: 18, height: 18 }} />
    </button>
  )
}

const Chat = () => {
  const [inputValue, setInputValue] = useState<string>('')
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [isMicOn, setIsMicOn] = useState(Speech.isMicOn)

  const onSend = (text: string) => {
    if (text.length > 0) {
      AITeacher.stopSpeak()
      WS.emitChatMessage({ text })
      setInputValue('')
    }
  }

  const onMessage = (msg: ChatMessage) => {
    setMessages((cur) => [...cur, msg])
    if (!msg.is_mine) AITeacher.speak(msg.text)
  }

  useEffect(() => {
    const unsubMessages = WS.subscribe({ type: 'message', cb: onMessage })
    const unsubNodeText = WS.subscribe({
      type: 'nodeText',
      cb: (text) => {
        AITeacher.stopSpeak()
        onMessage({ text, is_mine: false })
      },
    })
    const unsubQuestionText = WS.subscribe({
      type: 'nextQuestion',
      cb: (question) => {
        if (question.text !== undefined) {
          AITeacher.stopSpeak()
          onMessage({ text: question.text, is_mine: false })
        }
      },
    })
    const unsubInterimText = Speech.subscribe({ type: 'interimText', cb: setInputValue })
    const unsubResult = Speech.subscribe({ type: 'result', cb: onSend })
    const unsubSpeechListen = Speech.subscribe({ type: 'mic', cb: setIsMicOn })

    return () => {
      unsubMessages()
      unsubNodeText()
      unsubQuestionText()
      unsubInterimText()
      unsubResult()
      unsubSpeechListen()
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button>{/*<img src={settingsImg} />*/}</button>
        <span>AIWA</span>
        <VoiceBtn />
      </div>
      <div className={styles.scroller}>
        {/*Важный для скрола див. Не убирать*/}
        <div>
          {messages.map((m, i) => (
            <p key={i} className={classNames([styles.message, { [styles.myMsg]: m.is_mine }])}>
              {m.text}
            </p>
          ))}
        </div>
      </div>
      <div className={styles.footer}>
        <input
          readOnly={isMicOn}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyUp={(e) => e.code === 'Enter' && !e.shiftKey && onSend(inputValue)}
          style={isMicOn ? { opacity: 0.4, pointerEvents: 'none' } : {}}
        />
        <button onClick={() => onSend(inputValue)} style={isMicOn ? { opacity: 0.4, pointerEvents: 'none' } : {}}>
          <img src={sendImg} />
        </button>
        <MicrophoneBtn />
      </div>
    </div>
  )
}

export default Chat
