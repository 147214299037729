// import { console.log, getLang } from '../utils'
// import { store } from '../store/store'
// import wordsToNumbers from 'words-to-numbers'

import AITeacher from './AITeacher'

const rec = window.SpeechRecognition || window.webkitSpeechRecognition || undefined

const showLogs = true

function* idGenerator(): Generator<number> {
  let id = 0
  while (true) {
    yield id
    id++
  }
}
const createId = idGenerator()

class Speech {
  static isInit = false
  static isListen = false // Слушает ли реально
  private static _isMicOn = false // Нажмали ли на микрофон
  static get isMicOn() {
    return Speech._isMicOn
  }
  static set isMicOn(v: boolean) {
    Speech._isMicOn = v
    Speech.subscribes.forEach((s) => s.type === 'mic' && s.cb(v))
  }
  private static _isPause = false
  static get isPause() {
    return Speech._isPause
  }
  static set isPause(v: boolean) {
    Speech._isPause = v
    Speech.subscribes.forEach((s) => s.type === 'pause' && s.cb(v))
  }

  private static recognition: any = null
  private static subscribes: SRSubscribe[] = []

  static init() {
    if (rec === undefined || Speech.isInit) return
    Speech.isInit = true

    Speech.recognition = new rec()
    Speech.recognition.continuous = true
    Speech.recognition.interimResults = true
    Speech.recognition.maxAlternatives = 3
    Speech.recognition.lang = location.search.includes('lang=ru') ? 'ru-RU' : 'en-US'
    console.log('Speech recognition lang', Speech.recognition.lang)
    // Speech.recognition.lang = 'ru-RU'

    Speech.recognition.onstart = () => {
      showLogs && console.log('User speech ON_START')
      Speech.isListen = true
      Speech.subscribes.forEach((s) => s.type === 'listen' && s.cb(true))
    }
    Speech.recognition.onresult = function (event: any) {
      showLogs && console.log('User speech ON_RESULT()')
      for (const result of event.results) {
        if (result[0] === undefined) continue
        const resultTxt = result[0].transcript
        showLogs && console.log('User speech text:', resultTxt, result.isFinal)
        // const nodeType = store.getState().lesson.node?.type
        // if (nodeType === 3 || nodeType === 4) resultTxt = (wordsToNumbers(resultTxt) ?? '').toString()
        if (result.isFinal) {
          Speech.recognition.abort()
          Speech.subscribes.forEach((s) => s.type === 'result' && s.cb(resultTxt))
        } else Speech.subscribes.forEach((s) => s.type === 'interimText' && s.cb(resultTxt))
      }
    }
    Speech.recognition.onend = () => {
      showLogs && console.log('User speech ON_END')
      Speech.isListen = false
      Speech.subscribes.forEach((s) => s.type === 'interimText' && s.cb(''))
      Speech.subscribes.forEach((s) => s.type === 'listen' && s.cb(false))
      if (Speech.isMicOn && !Speech.isPause) {
        showLogs && console.log('User speech listen() after onend')
        setTimeout(() => {
          Speech.isMicOn = false
          Speech.listen()
        }, 500)
      }
    }
    Speech.recognition.onerror = (e: any) => {
      showLogs && console.log('User speech ON_ERROR')
      if (e.error === 'not-allowed') Speech.stopListen()
    }

    AITeacher.subscribe({ type: 'speak', cb: (v) => (v ? Speech.pause() : Speech.continue()) })
  }

  static listen() {
    showLogs && console.log('User speech listen()')
    if (!Speech.isInit) throw new Error("Speech doesn't init")
    const bool = !Speech.isListen && !Speech.isPause && !Speech.isMicOn
    showLogs && console.log('User speech listen ' + bool)
    if (bool) Speech.recognition.start()
    Speech.isMicOn = true
  }

  static stopListen() {
    showLogs && console.log('User speech stopListen()')
    if (!Speech.isInit) throw new Error("Speech doesn't init")
    Speech.recognition.abort()
    Speech.isMicOn = false
  }

  static pause() {
    showLogs && console.log('User speech pause()')
    if (!Speech.isInit) throw new Error("Speech doesn't init")
    if (Speech.isMicOn && Speech.isListen) {
      showLogs && console.log('User speech abort()')
      Speech.recognition.abort()
    }
    showLogs && console.log('User speech pause true')
    Speech.isPause = true
  }

  static continue() {
    showLogs && console.log('User speech continue()')
    if (!Speech.isInit) throw new Error("Speech doesn't init")
    const bool = Speech.isPause && Speech.isMicOn && !Speech.isListen
    showLogs && console.log('User speech pause false')
    Speech.isPause = false
    if (bool) {
      showLogs && console.log('User speech continue start')
      Speech.recognition.start()
    }
  }

  static subscribe(args: SRSubscribeArgs) {
    const id = createId.next().value
    Speech.subscribes.push({ id, ...args })
    return () => Speech.unsubscribe(id)
  }

  static unsubscribe(id: number) {
    Speech.subscribes = Speech.subscribes.filter((s) => id !== s.id)
  }
}

export default Speech
