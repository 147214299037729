import React, { useEffect, useState } from 'react'
import styles from './Unreal.module.scss'
import { UnrealCoordinator } from 'classes/UnrealСoordinator'
import classNames from 'classnames'
import { WS } from '../../../../classes/WS'

type Props = {
  user: User
}

const Unreal = ({ user }: Props) => {
  const [unreal, setUnreal] = useState(UnrealCoordinator.unreal)

  const sendConnect2unreal = () => {
    if (UnrealCoordinator.unreal === null) UnrealCoordinator.connect2unreal()
  }

  useEffect(() => {
    if (unreal?.status === 'connected') WS.emitCurNode()
  }, [unreal])

  useEffect(() => {
    const initUnsub = UnrealCoordinator.subscribe({
      type: 'connected',
      cb: (connected) => {
        if (connected) {
          sendConnect2unreal()
          initUnsub()
        }
      },
    })
    const unrealUnsub = UnrealCoordinator.subscribe({ type: 'unreal', cb: setUnreal })

    if (!UnrealCoordinator.isConnected) {
      UnrealCoordinator.init(user)
    } else sendConnect2unreal()

    return () => {
      initUnsub()
      unrealUnsub()
      UnrealCoordinator.disconnect()
    }
  }, [])

  if (unreal !== null) {
    if (unreal.status === 'connected') {
      return (
        <iframe
          id={'unreal_frame'}
          src={unreal.url}
          className={classNames(styles.container, styles.iframe)}
          allowFullScreen
        />
      )
    } else if (unreal.status === 'connecting') {
      return (
        <div className={styles.container}>
          <h2>Connecting...</h2>
        </div>
      )
    } else {
      return (
        <div className={styles.container}>
          <h2>
            Your place in the queue is <b>{unreal.queue + 1}</b>
          </h2>
        </div>
      )
    }
  } else {
    return (
      <div className={styles.container}>
        <h2>Loading...</h2>
      </div>
    )
  }
}

export default Unreal
