import React, { useEffect } from 'react'
import styles from './Main.module.scss'
import Chat from 'pages/Main/components/Chat/Chat'
import logo from 'assets/images/Logo.png'
import signOut from 'assets/images/sign_out.svg'
import { WS } from 'classes/WS'
import Unreal from './components/Unreal/Unreal'
import api from '../../api/api'

type Props = {
  user: User
  onSignOut: () => void
}

const Main = ({ user, onSignOut }: Props) => {
  useEffect(() => {
    //WS.init(user, WS.emitSayHello)
    WS.init(user)
    api.patchResetLesson(user.token)
    // api.postStartLesson(user.token)
  }, [])

  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} />
          <span>/ AIWA</span>
        </div>
        <button onClick={onSignOut} className={styles.signOutBtn}>
          Sign out <img src={signOut} />
        </button>
      </header>
      <div className={styles.chat}>
        <Chat />
      </div>
      <div className={styles.unreal}>
        <Unreal user={user} />
      </div>
    </div>
  )
}

export default Main
