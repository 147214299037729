import { io, Socket } from 'socket.io-client'
import { BACKEND_DOMAIN } from 'api/api'

const showLogs = false

function* idGenerator(): Generator<number> {
  let id = 0
  while (true) {
    yield id
    id++
  }
}
const createId = idGenerator()

export class UnrealCoordinator {
  static isInit = false
  private static user: User
  static isConnected = false
  static unreal: Unreal | null = null
  private static socket: Socket | null
  private static subscribes: UnrealCoordinatorSubscribe[] = []

  static init(user: User) {
    if (UnrealCoordinator.isInit) return
    UnrealCoordinator.isInit = true
    UnrealCoordinator.user = user
    UnrealCoordinator._init()
  }

  private static _init() {
    const user = UnrealCoordinator.user

    UnrealCoordinator.socket = io(BACKEND_DOMAIN, {
      reconnectionDelay: 3000,
      randomizationFactor: 0,
      path: '/infrastructure',
    })

    UnrealCoordinator.socket.onAny((type, data) => {
      showLogs && console.log('UnrealCoordinator', type, data)
    })

    UnrealCoordinator.socket.on('connect_status', (status: { data: string }) => {
      if (status.data === 'connected' && UnrealCoordinator.socket !== null) {
        UnrealCoordinator.socket.emit('hello_client', {
          token: user.token,
          user_id: user.id,
          username: user.username,
          first_name: user.first_name,
          last_name: user.last_name,
          teacher: 'Xiaoli',
          developer_mode: process.env.MODE === 'development',
        })

        UnrealCoordinator.socket.on('hello', () => {
          UnrealCoordinator.isConnected = true
          UnrealCoordinator.subscribes.forEach((s) => s.type === 'connected' && s.cb(true))
        })

        UnrealCoordinator.socket.on('connect_to_unreal', (unreal) => {
          UnrealCoordinator.subscribes.forEach((s) => s.type === 'unreal' && s.cb(unreal))
        })

        UnrealCoordinator.socket.on('connect_error', () => {
          setTimeout(() => {
            UnrealCoordinator.disconnect()
            UnrealCoordinator.init(user)
          }, 2000)
        })

        UnrealCoordinator.socket.on('disconnect', UnrealCoordinator.disconnect)
      }
    })
  }

  static connect2unreal() {
    if (UnrealCoordinator.socket !== null && UnrealCoordinator.isConnected) {
      UnrealCoordinator.socket.emit('connect_to_unreal')
    }
  }

  static changeTeacher(teacher: string) {
    UnrealCoordinator.socket?.emit('change_teacher', { teacher })
  }

  static subscribe(args: UnrealCoordinatorSubscribeArgs) {
    const id: number = createId.next().value
    UnrealCoordinator.subscribes.push({ id, ...args })
    return () => UnrealCoordinator.unsubscribe(id)
  }

  static unsubscribe(id: number) {
    UnrealCoordinator.subscribes = UnrealCoordinator.subscribes.filter((s) => {
      return id !== s.id
    })
  }

  static disconnect = (e?: any) => {
    UnrealCoordinator.socket?.disconnect()
    UnrealCoordinator.socket?.close()
    UnrealCoordinator.socket = null
    UnrealCoordinator.isConnected = false
    UnrealCoordinator.isInit = false
    if (e !== undefined) UnrealCoordinator.init(UnrealCoordinator.user)
  }
}
