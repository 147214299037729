import React, { useEffect, useState } from 'react'
import './App.scss'
import { Route, Routes } from 'react-router-dom'
import Authorization from './pages/Authorization/Authorization'
import Main from './pages/Main/Main'
import Speech from './classes/Speech'
import { WS } from './classes/WS'
import { UnrealCoordinator } from './classes/UnrealСoordinator'

function App() {
  const [user, setUser] = useState<User>()

  const onSignOut = () => {
    localStorage.removeItem('token')
    setUser(undefined)
    WS.disconnect()
    UnrealCoordinator.disconnect()
  }

  useEffect(() => {
    Speech.init()
  }, [])

  return (
    <div className="App">
      {user === undefined ? (
        <Authorization onChange={setUser} />
      ) : (
        <Routes>
          <Route path={'/'} element={<Main user={user} onSignOut={onSignOut} />} />
        </Routes>
      )}
    </div>
  )
}

export default App
