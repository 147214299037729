import { io, Socket } from 'socket.io-client'
import { BACKEND_DOMAIN } from '../api/api'

const showLogs = false

function* idGenerator(): Generator<number> {
  let id = 0
  while (true) {
    yield id
    id++
  }
}
const createId = idGenerator()

export class WS {
  static isInit = false
  private static user: User
  static isConnected = false
  private static _isSaidHello = false
  static get isSaidHello() {
    return WS._isSaidHello
  }
  private static set isSaidHello(said: boolean) {
    WS._isSaidHello = said
    WS.subscribes.forEach((s) => s.type === 'saidHello' && s.cb(said))
  }
  private static socket: Socket | null
  private static subscribes: Subscribe[] = []

  static init(user: User, cb?: () => void) {
    if (WS.isInit) return
    WS.isInit = true
    WS.user = user
    WS.socket = io(BACKEND_DOMAIN, {
      reconnectionDelay: 3000,
      randomizationFactor: 0,
    })

    WS.socket.onAny((type, data) => {
      if (type !== 'teacher_expressions') showLogs && console.log('WS <<', type, data)
    })

    WS.socket.on('connect_status', (status: { data: string }) => {
      console.log('WS SOCKET CONNECTED')
      WS.isConnected = true
      if (status.data === 'connected' && WS.socket !== null) {
        const { id, username, token } = user
        const helloMes = { token, id, username, on_lesson: true, is_ue: false, lang: 'en' }
        console.log('WS >> hello', helloMes)
        WS.socket.emit('hello', helloMes)
        cb?.()

        WS.socket.on('disconnect', WS.disconnect)

        WS.socket.on('teacher_expressions', ({ replicas }: TeacherExpressions) => {
          // if (replicas) AITeacher.speak(replicas)
        })

        WS.socket.on('chat_answer', (chat: ChatMessage) => {
          WS.subscribes.forEach((s) => s.type === 'message' && s.cb(chat))
        })

        WS.socket.on('chat_voice', (chat: { type_chat_voice: 'record_voice' | 'mute_voice' }) => {
          WS.subscribes.forEach((s) => s.type === 'UEVoiceSync' && s.cb(chat.type_chat_voice))
        })

        WS.socket.on('text_node', (node: { text?: string; node_type: number; visemes: number[] }) => {
          const text = node.text?.replace(/\{(?:anim|img|camera):.*?}/g, ' ')
          if (text !== undefined) {
            WS.subscribes.forEach((s) => s.type === 'nodeText' && s.cb(text, node.node_type, node.visemes))
          }
        })

        WS.socket.on('next_question', (question: TestQuestion & { question_index: number }) => {
          if (question.text) {
            WS.subscribes.forEach((s) => s.type === 'nextQuestion' && s.cb(question))
          }
        })
      }
    })

    WS.socket.on('connect_error', () => {
      setTimeout(() => {
        WS.disconnect()
        WS.init(user, cb)
      }, 2000)
    })
  }

  static emitSayHello() {
    WS.socket?.emit('say_hello')
    WS.isSaidHello = true
  }

  static emitChatMessage(data: { text: string; is_voice?: boolean; question_index?: string }) {
    WS.socket?.emit('chat', data)
  }

  static emitCurNode() {
    WS.socket?.emit('current_node')
  }

  static emitTextNode(id?: number) {
    WS.socket?.emit('text_node', { id })
  }

  static emitReplicasTest() {
    WS.socket?.emit('replicas_test')
  }

  static emitSpeakingTeacherTime(data: { phrase: string; speak_time: number }) {
    WS.socket?.emit('speaking_time', { ...data })
  }

  static subscribe(args: SubscribeArgs) {
    const id = createId.next().value
    WS.subscribes.push({ id, ...args })
    return () => WS.unsubscribe(id)
  }

  static unsubscribe(id: number) {
    WS.subscribes = WS.subscribes.filter((s) => {
      return id !== s.id
    })
  }

  static disconnect = (e?: any) => {
    WS.socket?.disconnect()
    WS.socket?.close()
    WS.socket = null
    WS.isConnected = false
    WS.isInit = false
    WS.isSaidHello = false
    if (e !== undefined) WS.init(WS.user)
  }
}
